import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import styled from "styled-components";
import bs1 from "../../assets/homeAssets/bs1.png";
import bs2 from "../../assets/homeAssets/bs2.png";
import bs3 from "../../assets/homeAssets/bs3.jpg";
import bs4 from "../../assets/homeAssets/bs4.png";
import bs5 from "../../assets/homeAssets/bs5.png";
import shanysLogo from "../../assets/homeAssets/shanysLogo.png";
import { useTranslation } from "react-i18next";

const BestSellers = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <h1>
        <span>{t("bs1")} </span> {t("bs2")}
      </h1>
      <div className="items">
        <div className="item">
          <img src={bs5} alt="" />
          <hr />
          <div>
            <img src={shanysLogo} alt="" />
            <h2>PD232 Extrait de Parfum</h2>
            <h3>50ml</h3>
            <p>
              The PD232 perfume, inspired by Valentino Donna by Valentino, is
              elegant and sophisticated. It opens with bergamot and plum,
              follows with a heart of rose and iris, and closes with a warm base
              of vanilla, patchouli and sandalwood. Perfect for special
              occasions and to express class
            </p>
            <button>{t("seeMore")}</button>
          </div>
        </div>
        <div className="item">
          <img src={bs1} alt="" />
          <hr />
          <div>
            <img src={shanysLogo} alt="" />
            <h2>Crema Corpo Elasticizzante alla Bava di Lumaca</h2>
            <h3>250ml</h3>
            <p>
              This body cream with a rich and silky texture contains a high
              concentration of snail slime that allows a smoothing, hydrating
              and regenerating action on the skin. It is particularly indicated
              for blemishes such as stretch marks and scars and for all types of
              dry and atonic skin.
            </p>
            <button>{t("seeMore")}</button>
          </div>
        </div>
        <div className="item">
          <img src={bs2} alt="" />
          <div>
            <img src={shanysLogo} alt="" />
            <h2>Siero Viso Bava di Lumaca Roll-On 15ml</h2>
            <h3>15ml</h3>
            <p>
              Serum with a light and silky texture thanks to the presence of
              pure snail slime in high concentration. It offers an intensive
              hydrating, revitalizing, restructuring and anti-aging treatment
              for the eye area.
            </p>
            <button>{t("seeMore")}</button>
          </div>
        </div>
        <div className="item">
          <img src={bs3} alt="" />
          <div>
            <img src={shanysLogo} alt="" />
            <h2>Crema Doposole</h2>
            <h3>200ml</h3>
            <p>
              Fresh after-sun cream rich in plant extracts with soothing and
              refreshing action. Applied after sun exposure it gives immediate
              relief. It is quickly absorbed and leaves no residue.
            </p>
            <button>{t("seeMore")}</button>
          </div>
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
  h1 {
    font-family: "Alta";
    font-weight: 100;
    font-size: 6vw;
    span {
      color: var(--primaryColor);
    }
  }
  .items {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 7em;
    .item {
      height: 500px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2em;
      position: sticky;
      top: 5em;
      background-color: #ffffff8b;
      backdrop-filter: blur(15px);
      box-shadow: 0 0 12px 2px #d3d3d3;
      border-radius: 30px;
      padding-right: 10%;
      img {
        width: 45%;
        border-radius: 30px;
        max-height: 540px;
      }
      hr {
        height: 40vh;
      }
      div {
        img {
          width: 120px;
        }
        h2 {
          font-size: 3em;
          font-family: "Alta";
          font-weight: 100;
        }
        h3 {
          color: var(--primaryColor);
        }
        p {
          padding: 10px 0;
          color: gray;
        }
        button {
          background-color: var(--primaryColor);
          color: white;
          font-family: "Comfortaa";
          font-size: 18px;
          padding: 15px;
          border: none;
          cursor: pointer;
          transition: 0.4s;
          &:hover {
            background: #9b8c5d;
          }
        }
      }
    }
    :nth-child(2) {
      top: 7em;
    }
    :nth-child(3) {
      top: 9em;
    }
    :nth-child(4) {
      top: 11em;
    }
    :nth-child(5) {
      top: 13em;
    }
  }
  @media (max-width: 1200px) {
    .items {
      .item {
        div {
          h2 {
            font-size: 2em;
          }
          p {
            max-height: 100px;
            overflow: scroll;
          }
        }
      }
    }
  }
  @media (max-width: 850px) {
    h1 {
      font-size: 4em;
      text-align: center;
    }
    .items {
      width: 100%;
    }
  }
  @media (max-width: 650px) {
    .items {
      .item {
        flex-direction: column;
        height: 90vh;
        justify-content: center;
        align-items: center;
        padding-right: 0;

        hr {
          height: 1px;
          width: 90%;
        }
        div {
          padding: 10px 20px;
          text-align: center;
        }
      }
    }
  }
`;

export default BestSellers;
