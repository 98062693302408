import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination, Autoplay } from "swiper/modules";
import skincare from "../../assets/homeAssets/skincare3.webp";
import makeUp from "../../assets/homeAssets/makeUp.jpeg";
import perfumes from "../../assets/homeAssets/perfumes.webp";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Swiper
        className="swiper leftSwiper"
        pagination={true}
        modules={[Pagination, Autoplay]}
        slidesPerView={"auto"}
        spaceBetween={4}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide className="slide skincare">
          <img src={skincare} alt="" />
          <div className="details">
            <h1> {t("skincareProducts")}</h1>
            <p>{t("skincareDesc")}</p>
            <button>{t("seeMore")}</button>
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide makeUp">
          <img src={makeUp} alt="" />
          <div className="details">
            <h1>{t("makeUp")}</h1>
            <p>{t("makeUpDesc")}</p>
            <button>{t("seeMore")}</button>
          </div>
        </SwiperSlide>
        <SwiperSlide className="slide perfumes">
          <img src={perfumes} alt="" />
          <div className="details">
            <h1>{t("perfumes")}</h1>
            <p>{t("perfumesDesc")}</p>
            <button>{t("seeMore")}</button>
          </div>
        </SwiperSlide>
      </Swiper>
    </Container>
  );
};
const Container = styled.div`
  padding: 4.3em 0;
  display: flex;
  justify-content: center;
  .swiper {
    height: 85vh;
    .slide {
      display: flex;
      align-items: center;
      gap: 2em;
      width: 90%;
      height: 85vh;
      background: #f1f5f7;
      img {
        position: absolute;
        width: 100%;
        height: 85vh;
        object-fit: cover;
        z-index: 0;
      }
      .details {
        padding-left: 7%;
        z-index: 10;
        h1 {
          font-family: "Alta", sans-serif;
          font-size: 9vw;
          font-weight: 100;
        }
        button {
          background: white;
          color: black;
          font-family: "Comfortaa", sans-serif;
          font-size: 18px;
          padding: 10px 15px;
          border: none;
          margin-top: 10px;
          text-transform: uppercase;
        }
      }
    }
    .perfumes {
      .details {
        color: white;
      }
    }
    .makeUp {
      .details {
        align-self: start;
        justify-content: center;
        text-align: center;
        margin-top: 5%;
        width: 100%;
        h1 {
          font-size: 14vw;
          line-height: 0.7em;
        }
        button {
          background: #1b1b1b;
          color: white;
        }
      }
    }
    .skincare {
      img {
        transform: scaleX(-1); /* Flips the image horizontally */
        -webkit-transform: scaleX(-1);
      }
      .details {
        color: white;
        h1 {
          max-width: 70%;
        }
      }
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .swiper-pagination-bullet {
    width: 24px;
    height: 7px;
    background-color: #ffffff; /* Change to your desired color */
    opacity: 0.7;
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 3px 1px gray;
    transition: background-color 0.3s ease;
  }

  .swiper-pagination-bullet-active {
    background-color: #ada491; /* Change to your desired active color */
    opacity: 1;
  }
  /* @media (min-width: 1500px) {
    .swiper {
      .slide {
        padding: 3em 5%;
        img {
          width: 49%;
          height: auto;
        }
        .left {
          h1 {
            font-size: 5vw;
            line-height: 1.1em;
          }
        }
      }
    }
  } */
  @media (max-width: 1300px) {
    .swiper {
      .slide {
        height: 80vh;
        .left {
          h1 {
            font-size: 3.7em;
          }
        }
      }
    }
  }
  @media (max-width: 1000px) {
    .swiper {
      .slide {
        width: 99%;
        gap: 1em;
        img {
          height: 85vh;
        }
      }
    }
  }
  @media (max-width: 940px) {
    .swiper {
      /* margin-top: 2em; */
      .slide {
        height: 70vh;
        img {
          width: 100%;
          height: 70vh;
        }
      }
      .slide2 {
        .left {
        }
      }
    }
  }
  @media (max-width: 700px) {
    .swiper {
      .slide {
        width: 99%;
        .details {
          h1 {
            font-size: 15vw;
          }
        }
        img {
          width: 100%;
        }
      }
      .makeUp {
        .details {
          h1 {
            padding-top: 40px;
          }
        }
      }
    }
  }
  @media (max-width: 600px) {
    .swiper {
      .slide {
        padding-right: 10%;
      }
      .makeUp {
        padding-right: 5%;
        .details {
          h1 {
            padding-top: 40px;
          }
        }
      }
    }
  }
`;

export default Banner;
