import React, { useEffect, useRef, useState, useContext } from "react";
import styled from "styled-components";
import { Link, useParams, useNavigate } from "react-router-dom";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { motion, useAnimation, useInView } from "framer-motion";
import { CartContext } from "../CartContext";
import { BsCashCoin } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const ProductPage = () => {
  const { name } = useParams();
  const [products, setProducts] = useState([]);
  const cart = useContext(CartContext);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, "products");
        const productsSnapshot = await getDocs(productsCollection);
        const productsList = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productsList);
      } catch (error) {
        console.error("Error fetching products: ", error);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = `${name} | Beautyrocks Cosmetics`;
  }, [name]);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const animation = useAnimation();

  useEffect(() => {
    if (isInView) {
      animation.start("visible");
    }
    // eslint-disable-next-line
  }, [isInView]);

  const product = products.find((item) => item.name === name);

  const handleAddToCart = () => {
    if (product) {
      cart.addOneToCart(product.id);
    }
  };

  const handleBuyNow = () => {
    if (product) {
      cart.addOneToCart(product.id);
      navigate("/checkout");
    }
  };
  const getProductPrice = (size) => {
    switch (size) {
      case "25ml":
        return 9.99;
      case "50ml":
        return 14.99;
      case "100ml":
        return 24.99;
      default:
        return 9.99;
    }
  };

  return (
    <Container>
      {product ? (
        <>
          <img src={product.image} alt={product.name} />
          <hr />
          <div className="right">
            <h3>{product.brand}</h3>
            <h2>{product.name}</h2>
            <h1>€{product.price.toFixed(2)}</h1>
            {/* <h3>
              Madhësia:
              <select
                value={selectedSize}
                onChange={(e) => setSelectedSize(e.target.value)}
              >
                <option value="30ml">30ml</option>
                <option value="50ml">50ml</option>
                <option value="100ml">100ml</option>
              </select>
            </h3> */}
            <h3>{t("availability")}</h3>
            <h3>
              {t("seaons")}:{" "}
              <span>
                {Array.isArray(product.seasons)
                  ? product.seasons.join(", ")
                  : product.seasons}
              </span>
            </h3>
            {/* <h3>
              Kategoria: <span>{product.category}</span>
            </h3> */}

            <p>{product.description}</p>
            <div className="btns">
              <button onClick={handleBuyNow}>{t("buyNow")}</button>
              <button onClick={handleAddToCart}>{t("addToCart")}</button>
            </div>
            <div className="paymentMethods">
              <span>
                <BsCashCoin className="icon" />
                <p>{t("note")}</p>
              </span>
              {/* <span>
                <IoIosCard className="icon" />
                <p>Paguaj online</p>
              </span> */}
            </div>
          </div>
        </>
      ) : (
        <p>Product not found</p>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 7em 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2em;
  transition: 0.5s;
  img {
    width: 40%;
    max-height: 60vh;
    object-fit: contain;
    transition: 0.5s;
  }
  hr {
    height: 70vh;
    border: none;
    border-left: 1px solid #686868;
  }
  .right {
    width: 57%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    h2 {
      font-size: 2.6em;
      font-family: "Josefin Sans", sans-serif;
      font-weight: 400;
      text-transform: capitalize;
      color: #343434;
    }
    h3 {
      font-weight: 400;
      color: #2e2e2e;
      text-transform: capitalize;
      span {
        color: var(--primaryColor);
        text-transform: capitalize;
      }
    }
    h1 {
      font-size: 2em;
      font-weight: 400;
      color: #3f3f3f;
    }
    p {
      color: #a0a0a0;
      line-height: 1.6em;
      font-weight: 300;
      font-size: 15px;
    }
    .btns {
      display: flex;
      gap: 7px;
      button {
        margin-top: 1em;
        font-family: "Montserrat", sans-serif;
        color: white;
        font-size: 1.2em;
        padding: 13px 20px;
        background: #2c2c2c;
        border: 1px solid #b9aea7;
        cursor: pointer;
        transition: 0.4s;
        &:hover {
          background: var(--primaryColor);
          color: white;
        }
      }
      :nth-child(2) {
        background: none;
        color: #2b2b2b;
      }
    }
    .paymentMethods {
      margin-top: 2em;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 1em;
      span {
        display: flex;
        align-items: center;
        gap: 7px;
        p {
          font-size: 15px;
        }
      }
    }
  }
  @media (max-width: 730px) {
    flex-direction: column;
    .right {
      text-align: center;
      align-items: center;
      width: 100%;
    }
    hr {
      height: 1px;
      width: 100%;
      border-left: none;
      border-top: 1px solid #a0a0a0;
    }
    img {
      width: 100%;
      flex-direction: column;
      align-items: center;
      max-height: 40vh;
    }
  }
  @media (max-width: 550px) {
    .imageDiv {
      /* img {
        height: 60vh;
        width: auto;
      } */
      .gallery {
        img {
          width: auto;
          height: 70px;
        }
      }
    }
  }
  @media (max-width: 450px) {
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      height: 30vh;
    }
    .right {
      text-align: start;
      align-items: flex-start;
    }
  }
`;

export default ProductPage;
